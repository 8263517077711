import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Newsletter = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className, options } = field
  return (
    <div className={`newsletter-field${name ? ' active' : ''}${required ? ' required' : ''}`}>
        <div className="checkboxes">
          <label htmlFor={name} className="checkbox">
            <input name={name} id={name} checked={value[name] ? true : false} type="checkbox" onChange={handleChange} value='yes'  />
            {placeholder}
          </label>
        </div>
    </div>
  )
}
