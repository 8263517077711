import React from 'react';
import { decodeEntities } from '../../../utils/helpers';

export const Title = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className } = field
  return (
    <div className="title">
      {placeholder && <h4 dangerouslySetInnerHTML={{ __html: decodeEntities(placeholder) }} />}
    </div>
  )
}
