import React from 'react';
import { slugify } from '../../../utils/helpers'

export const Checkbox = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className, options } = field
  return (
    <div className={`${className}${value[slugify(name)] ? ' active' : ''}${required ? ' required' : ''}`}>
        <div className="checkboxes">
        {options && options.map((option, index) => (
          <label htmlFor={slugify(option.label)} className="checkbox" key={index}>
            <input name={slugify(option.label)} checked={value[slugify(option.label)] === option.value} type="checkbox" onChange={handleChange} value={option.value}  />
            {option.label}
          </label>
        ))}
        </div>
    </div>
  )
}
